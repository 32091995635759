/**
 * Simple filters.
 *
 * @link https://lesprx.monday.com/docs/4822789543
 * @author Les Prétentieux
 * @version 1.0.0
 *
 * */

"use strict";

// https://github.com/farzher/fuzzysort
import fuzzysort from "fuzzysort";

export class PrxFilters {
	/**
	 * Init our class
	 *
	 */
	constructor({ searchThreshold = -Infinity } = {}) {
		this.searchThreshold = searchThreshold;

		this.filtersElems = [];

		this.init();
	}

	/**
	 * Initialize filters
	 */
	init() {
		// Get all filters
		this.filtersElems = document.querySelectorAll("[data-filters]");

		this.filtersElems.forEach((filtersElem) => {
			// Get the filters params
			var filtersParams = filtersElem.dataset.filters;
			filtersParams = JSON.parse(filtersParams);

			// Store those params in the filters node
			var paramsObj = {
				for: filtersParams.for,
				urlHash: filtersParams.urlHash ? filtersParams.urlHash : false,
				onSubmit: filtersParams.onSubmit ? filtersParams.onSubmit : false,
			};
			filtersElem = Object.assign(filtersElem, paramsObj);

			// Preemptively build filters object
			this.buildFiltersObject(filtersElem);

			// Get the filters filter elements
			var filterElems = filtersElem.querySelectorAll("[data-filter]");

			// Build filters object on input
			if (filtersElem.onSubmit) {
				var filterSubmit = filtersElem.querySelector("[data-filter-submit]");
				filterSubmit.addEventListener("click", () => this.buildFiltersObject(filtersElem));
			} else {
				filterElems.forEach((filterElem) => {
					filterElem.addEventListener("input", () => this.buildFiltersObject(filtersElem));
				});
			}
		});
	}

	/**
	 * Build filters object for a given filters element
	 */
	buildFiltersObject(filtersElem) {
		var filtersObj = [];

		// Get filter groups
		var filterGroups = filtersElem.querySelectorAll("[data-filter-group]");

		filterGroups.forEach((filterGroup) => {
			var filtersGroupObj = {};
			var filterElems = filterGroup.querySelectorAll("[data-filter]");

			// Loop through each filters of the group
			filterElems.forEach((filterElem) => {
				var key = filterElem.dataset.filter;
				var value = "";
				var type = filterElem.type;

				if (type == "radio" || type == "checkbox") {
					if (filterElem.checked) {
						value = filterElem.value;
					}
				} else {
					value = filterElem.value;
				}

				if (filtersGroupObj[key] == undefined) {
					filtersGroupObj[key] = [];
				}

				if (value) {
					filtersGroupObj[key].push(value);
				}
			});

			// Add filters group object to the main filters object
			filtersObj.push(filtersGroupObj);
		});

		// Store filters object in the filters node
		filtersElem = Object.assign(filtersElem, { filtersObj: filtersObj });

		if (filtersElem.urlHash) {
			this.buildUrl(filtersElem);
		}

		this.updateView();
	}

	/**
	 * Builds the URL hash
	 */
	buildUrl(filtersElem) {
		const url = new URL(window.location.href);

		var filtersObj = filtersElem.filtersObj;

		filtersObj.forEach((filtersGroupObj) => {
			for (var key in filtersGroupObj) {
				if (filtersGroupObj[key] != "") {
					url.searchParams.set(key, filtersGroupObj[key]);
				} else {
					url.searchParams.delete(key);
				}
			}
		});

		window.history.pushState("", "", url);
	}

	/**
	 * Updates view
	 */
	updateView() {
		this.filtersElems.forEach((filtersElem) => {
			var resultsObj = [];
			var filtersObj = filtersElem.filtersObj;
			var filterFor = filtersElem.for;

			var filterItemsElems = document.querySelectorAll('[data-filter-items="' + filterFor + '"]');

			filterItemsElems.forEach((filterItemsElem) => {
				var isEmpty = true;

				filterItemsElem.querySelectorAll("[data-filter-item]").forEach((filterItem) => {
					var groupMatches = 0;
					var filterItemObj = filterItem.dataset.filterItem;
					filterItemObj = JSON.parse(filterItemObj);

					filtersObj.forEach((filtersGroupObj) => {
						var match = false;
						var groupActive = false;

						for (var key in filtersGroupObj) {
							var filterItemValue = filterItemObj[key];

							if (filterItemValue) {
								filterItemValue = filterItemValue.split(",");
							} else {
								filterItemValue = [];
							}

							var filtersObjValue = filtersGroupObj[key];

							if (key == "fsearch") {
								if (filtersObjValue.length) {
									groupActive = true;

									var search = filtersObjValue[0];
									const targets = [filterItemObj];
									const options = {
										threshold: this.searchThreshold,
										keys: Object.keys(filterItemObj),
									};

									const results = fuzzysort.go(search, targets, options);

									if (results.total > 0) {
										var resultsArr = [];
										match = true;

										results.forEach((result) => {
											for (let index = 0; index < Object.keys(filterItemObj).length; index++) {
												if (result[index]) {
													resultsArr.push({
														key: Object.keys(filterItemObj)[index],
														hightlight: fuzzysort.highlight(result[index], "<x-searchmatch>", "</x-searchmatch>"),
														score: result.score,
													});
												}
											}
										});

										resultsObj.push({
											node: filterItem,
											results: resultsArr,
										});

										break;
									}
								}
							} else {
								if (filtersObjValue.length && filterItemValue.length) {
									groupActive = true;

									if (filtersObjValue.some((item) => filterItemValue.includes(item))) {
										match = true;
										break;
									}
								}
							}
						}

						if ((groupActive && match) || !groupActive) {
							groupMatches++;
						}
					});

					if (groupMatches == filtersObj.length) {
						filterItem.classList.add("is-match");
						isEmpty = false;
					} else {
						filterItem.classList.remove("is-match");
					}
				});

				if (isEmpty) {
					filterItemsElem.classList.add("is-empty");
				} else {
					filterItemsElem.classList.remove("is-empty");
				}

				filtersElem.dispatchEvent(
					new CustomEvent("filters:after", {
						detail: { isEmpty: isEmpty, searchResults: resultsObj },
					})
				);
			});
		});
	}
}
