/**
 * Baisc marquee with mouse wheel acceleration.
 *
 * @link   https://lespretentieux.slite.com/app/docs/QNwX-C3FSydkrG
 * @author Les Prétentieux
 *
 * */

"use strict";

export class PrxMarquee {
	/**
	 * Init our class
	 *
	 */
	constructor() {
		this.easing = 0.04;

		this.defaultSpeed = 1;
		this.defaultScrollAcceleration = 0.1;

		this.scrollSpeed = 0;

		// Store our marquee values
		this.prxMarquee = {};

		this.marqueeElems = [];

		// Add our event listeners
		window.addEventListener("wheel", (event) => this.wheelEvent(event), { passive: true });
		window.addEventListener("resize", () => this.refresh(), { passive: true });

		this.init();
	}

	/**
	 * Update scroll speed on mouse wheel input
	 */
	wheelEvent(event) {
		this.scrollSpeed = Math.abs(event.deltaY);
	}

	/**
	 * Initialize marquees
	 */
	init() {
		window.requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;

		this.refresh();

		requestAnimationFrame(() => this.tick());
	}

	/**
	 * Update our marquee related elements
	 */
	refresh() {
		// Get all our elements
		this.marqueeElems = document.querySelectorAll("[data-marquee]");

		this.marqueeElems.forEach((marqueeElem) => {
			// Set defaults
			var direction = marqueeElem.dataset.direction;
			var speed = marqueeElem.dataset.speed;
			var scrollAcceleration = marqueeElem.dataset.scrollAcceleration;

			direction = direction ? direction : "forwards";
			speed = speed ? speed : this.defaultSpeed;
			scrollAcceleration = scrollAcceleration ? scrollAcceleration : this.defaultScrollAcceleration;

			// Create the appropriate amount of content duplicates
			var clonedContentElems = marqueeElem.querySelectorAll(".c-marquee_content.is-clone");

			var wrapperEl = marqueeElem.querySelector(".c-marquee_wrapper");
			var contentEl = marqueeElem.querySelector(".c-marquee_content");

			clonedContentElems.forEach((clonedContentElem) => {
				clonedContentElem.remove();
			});

			var marqueeWidth = this.outerWidth(marqueeElem);
			var wrapperWidth = this.outerWidth(wrapperEl);

			if (wrapperWidth > 0) {
				var diff = marqueeWidth / wrapperWidth;
				var duplications = Math.ceil(diff + 1);

				for (var x = 0; x < duplications; x++) {
					var clone = wrapperEl.appendChild(contentEl.cloneNode(true));
					clone.classList.add("is-clone");
				}
			}

			// Store our marquee object values in the node
			this.prxMarquee = {
				direction: direction,
				speed: speed,
				scrollAcceleration: scrollAcceleration,
				transform: direction == "reverse" ? -wrapperWidth : 0,
			};

			marqueeElem = Object.assign(marqueeElem, this.prxMarquee);
		});
	}

	/**
	 * Tick 60 times per second with requestAnimationFrame
	 */
	tick() {
		// Todo: if in view
		this.render();

		requestAnimationFrame(() => this.tick());
	}

	/**
	 * Render/animate our marquee
	 */
	render() {
		// Get smoothed out scroll speed offset
		this.scrollSpeed = this.round(this.scrollSpeed);
		this.scrollSpeed = this.lerp(this.scrollSpeed, 0, 0.04);

		this.marqueeElems.forEach((marqueeElem) => {
			var direction = marqueeElem.direction;
			var speed = marqueeElem.speed;
			var scrollAcceleration = marqueeElem.scrollAcceleration;

			var wrapperEl = marqueeElem.querySelector(".c-marquee_wrapper");
			var contentEl = marqueeElem.querySelector(".c-marquee_content");

			var transformX = marqueeElem.transform;

			if (direction == "reverse") {
				if (transformX > 0) {
					transformX = -this.outerWidth(contentEl);
				}
				transformX = transformX + (speed * 2 + this.scrollSpeed * scrollAcceleration);
			} else {
				if (-transformX > this.outerWidth(contentEl)) {
					transformX = 0;
				}
				transformX = transformX - (speed * 2 + this.scrollSpeed * scrollAcceleration);
			}

			transformX = this.round(transformX);

			marqueeElem.transform = transformX;

			wrapperEl.style.transform = "translate3d(" + transformX + "px, 0, 0)";
		});
	}

	/**
	 * Utility function to linear interpolate values
	 *
	 * @param start (float): value to lerp
	 * @param end (float): target value
	 * @param amount (float): easing
	 *
	 * @returns (float): lerped value
	 */
	lerp(start, end, amt) {
		return (1 - amt) * start + amt * end;
	}

	/**
	 * Utility function to get width with margins included
	 *
	 * @param el (node): element you want to calculate the width of
	 *
	 * @returns (float): width
	 */
	outerWidth(el) {
		var width = el.offsetWidth;
		var style = getComputedStyle(el);

		width += parseInt(style.marginLeft) + parseInt(style.marginRight);
		return width;
	}

	/**
	 * Shortcut for rounding number to second decimal place
	 *
	 * @returns (number): rounded number to second decimal place
	 */
	round(number) {
		return Math.round((number + Number.EPSILON) * 100) / 100;
	}
}
