export default function () {
	$(".c-accordion-item").on("click", function () {
		$(this).children(".accordion-content").slideToggle(300);
		
	});

	$(".c-accordion_body .c-accordion_action .btn-red").click(function (e) {
		e.stopPropagation();
		var jobName = $(this).closest(".c-accordion_single.-job").find(".c-accordion_head h4").text();

		$('input[name="your-poste"]').val(jobName);
	});




	let headerAccordions = document.querySelectorAll('.c-accordion-item.-services');


	for (let i = 0; i < headerAccordions.length; i++) {
		let icon = headerAccordions[i].querySelector('.c-accordion_toggle.-center');
		
		headerAccordions[i].addEventListener('click', () => {
			icon.classList.toggle('is-open');


			console.log('click ici')
		})
	}

	
}
