export default function () {
	// $(".js-filter").on("click", function () {
	// 	var filterType = $(this).attr("data-filter");

	// 	$(".js-filter, .js-reset-filter").removeClass("active");
	// 	$(this).addClass("active");
	// 	$(".c-produits__categories-listing li, .c-boutique-listing .products li").hide();
	// 	$(`.product_cat-${filterType}`).show();
	// });

	// $(".js-reset-filter").on("click", function () {
	// 	$(".js-filter").removeClass("active");
	// 	$(this).addClass("active");
	// 	$(".c-produits__categories-listing li, .c-boutique-listing .products li").show();
	// });

	$(".c-filter_item").on("click", function () {
		$(".c-filter_item").removeClass("is-active");
		$(this).addClass("is-active");
	});
}
