export default function () {

	$('.js-header-burger').click(function() {
		$(this).toggleClass('active');

		if($('body').hasClass('menu-is-open')) {
			$('body').removeClass('menu-is-open').addClass('menu-is-close');
		} else {
			$('body').removeClass('menu-is-close').addClass('menu-is-open');
		}
	});

   $(document).keydown(function(e){

        if(e.keyCode == 27) {
			$('body').removeClass('menu-is-open').addClass('menu-is-close');
			$('.js-header-burger').removeClass('active');
        }
    });
}