export default function () {
	var $ = jQuery;

	$("[data-lightbox]").click(function () {
		var id = $(this).data("lightbox");
		var $lightbox = $('[data-lightbox-id="' + id + '"]');
		$lightbox.addClass("is-active");
		$lightbox.find(".o-carousel").flickity("resize");
	});

	$(".c-lightbox_inner").click(function (e) {
		if (!$(e.target).is(".c-lightbox_content, .c-lightbox_content *")) {
			closeLightbox($(this).closest(".c-lightbox"));
		}
	});

	$(".c-lightbox_clear").click(function () {
		closeLightbox($(this).closest(".c-lightbox"));
	});

	function closeLightbox($lightbox) {
		$lightbox.removeClass("is-active");
		if ($lightbox.find("video").length > 0) {
			$lightbox.find("video")[0].pause();
		}
	}
}
