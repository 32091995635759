export default function () {

	$('.c-tabs .c-tabs__links a').on('click', function(e)  {
		e.preventDefault();

		var currentAttrValue = $(this).attr('href');

		$('.c-tabs ' + currentAttrValue).show().siblings().hide();
		
		$(this).parent('li').addClass('active').siblings().removeClass('active');
	});

	$('.c-career .c-career__links a').on('click', function(e)  {
		e.preventDefault();

		var currentAttrValue = $(this).attr('href');

		$('.c-career__content ' + currentAttrValue).show().siblings().hide();
		
		$(this).parent('li').addClass('active').siblings().removeClass('active');
	});
}