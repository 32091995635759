export default function () {
	jQuery(".c-modal_button").click(function (e) {
		e.stopPropagation();
		jQuery(".c-modal").addClass("is-active");
		jQuery("html, body").addClass("hidden");
	});

	jQuery(".c-modal_close").click(function (e) {
		e.stopPropagation();
		jQuery(".c-modal").removeClass("is-active");
		jQuery("html, body").removeClass("hidden");
	});
}
