export default function () {
	document.addEventListener(
		"wpcf7submit",
		function (event) {
			// find only disbaled submit buttons
			var button = $(".c-form .c-button");

			// grab the old value
			var old_value = button.attr("data-value");

			// enable the button
			button.prop("disabled", false);
			button.removeClass("-disabled");

			// put the old value back in
			button.val(old_value);
		},
		false
	);

	$(".wpcf7-form").on("submit", function () {
		var form = $(this);
		var button = form.find("button[type=submit]");
		var current_val = button.val();

		// store the current value so we can reset it later
		button.attr("data-value", current_val);

		// disable the button
		button.prop("disabled", true);
		button.addClass("-disabled");

		// tell the user what's happening
		button.val("Envoi en cours");
	});
}
