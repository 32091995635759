export default function () {

    $('.js-trigger-eval-form').on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();        
        $('.js-eval-form').addClass('active');
        $('body').css('overflow', 'hidden');
    })

    $('.close-eval-form').on('click', function() {
        triggerForm();
        
    })

    $(document).keyup(function(e) {

        if (e.keyCode == 27) {
            triggerForm();
        }
    })

    $('body').on('click', function() {
        triggerForm();
    })

    $('.js-eval-form .wrapper').on('click', function(e) {
        e.stopPropagation();
    })

    function triggerForm() {
        $('.js-eval-form').removeClass('active');  
        $('body').css('overflow', 'visible');
    }
}