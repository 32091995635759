export default function () {
	jQuery(".c-team-members_seemore").click(function (e) {
		e.stopPropagation();
		jQuery(this).parent().parent().find(".c-team-members_single-modal").addClass("is-active");
		jQuery("html, body").addClass("hidden");
	});

	jQuery(".c-team-members_close").click(function (e) {
		e.stopPropagation();
		jQuery(this).parent().parent().parent().removeClass("is-active");
		jQuery("html, body").removeClass("hidden");
	});
}
