export default function() {

	let lastScrollTop = 0;

	$(window).on('scroll', function() {
		let top = $(document).scrollTop();

		if (top < lastScrollTop) {
			// Scrolling up, add the class
			$('.c-header').addClass('is-sticky');
			$('.c-header__burger').addClass('is-sticky');
		} else {
			// Scrolling down or not scrolling, remove the class
			$('.c-header').removeClass('is-sticky');
			$('.c-header__burger').removeClass('is-sticky');
		}

		lastScrollTop = top;
	});
}