import $ from 'jquery';

export default function() {

	$('.c-form input[type="file"]').change(function() {
	    if ($(this).val()) {
	         var filename = $(this).val();
	         
	         $('.filename').html(filename);
	    }
	});
}