export default function () {
  jQuery(".c-filters_link").click(function () {
    jQuery(".c-filters_link").removeClass("is-active");
    jQuery(this).addClass("is-active");

    var activeCategory = jQuery(this).data("cat-filter");

    jQuery("[data-cat]").each(function () {
      var elCategories = jQuery(this).data("cat");
      if (!elCategories.includes(activeCategory) && activeCategory != "all") {
        jQuery(this).hide();
      } else {
        jQuery(this).show();
      }
    });
  });
}
