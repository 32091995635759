import { PrxMarquee } from "../js/modules/marquee";
import { PrxFilters } from "../../../libraries/prx-filters/prx-filter";

// Prx Marquee
const marquee = new PrxMarquee();

// Prx Filter
var filtersInstance;
filtersInstance = new PrxFilters();

import start from "./modules/router";
import scroller from "./modules/scroller";
import slick from "./modules/slick";
import burgerMenu from "./modules/burgerMenu";
import tabs from "./modules/tabs";
import fileName from "./modules/fileName";
import sticky from "./modules/sticky";
import productSingle from "./modules/productSingle";
import toggle from "./modules/toggle";
import loader from "./modules/loader";
import evalForm from "./modules/evalForm";
import firefox from "./modules/firefox";
import smoothScroll from "./modules/smoothScroll";
import filters from "./modules/filters";
import covid from "./modules/covid";
import backToTop from "./modules/backToTop";
import productsFilter from "./modules/productsFilter";
import modalPopup from "./modules/modalPopup";
import accordion from "./modules/accordion";
import video from "./modules/video";
import lightbox from "./modules/lightbox";
import scrollShow from "./modules/scrollShow";
import modal from "./modules/modal";
import cf7 from "./modules/cf7";

scroller();
slick();
burgerMenu();
tabs();
fileName();
sticky();
productSingle();
toggle();
loader();
evalForm();
firefox();
smoothScroll();
filters();
covid();
backToTop();
productsFilter();
modalPopup();
accordion();
video();
lightbox();
scrollShow();
modal();
cf7();
