export default function () {
	$(".c-home-hero_slider").slick({
		infinite: true,
		arrows: true,
		prevArrow: '<div class="prevArrow"></div>',
		nextArrow: '<div class="nextArrow"></div>',
		autoplay: true,
		autoplaySpeed: 4000,
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: true,
		customPaging: function (slider, i) {
			return '<div class="slider-dot-wrapper"><div class="slider-dot"></div></div>';
		},
	});

	$(".c-testimonies_slider").slick({
		infinite: true,
		arrows: true,
		prevArrow: '<div class="prevArrow"></div>',
		nextArrow: '<div class="nextArrow"></div>',
		autoplay: true,
		autoplaySpeed: 4000,
		adaptiveHeight: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: true,
		customPaging: function (slider, i) {
			return '<div class="slider-dot-wrapper"><div class="slider-dot"></div></div>';
		},
	});

	$(document).ready(function () {
		$(".c-caroussel_slider").slick({
			infinite: true,
			arrows: true,
			prevArrow: $(".c-products-listing_arrow.-prev"),
			nextArrow: $(".c-products-listing_arrow.-next"),
			autoplay: true,
			autoplaySpeed: 4000,
			slidesToShow: 3,
			slidesToScroll: 1,

			responsive: [
				{
					breakpoint: 1050,
					settings: {
						slidesToShow: 2,
					},
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1,
					},
				},
			],
		});
	});

	$(".c-promo-listing_slider").slick({
		infinite: false,
		arrows: true,
		prevArrow: '<div class="prevArrow"></div>',
		nextArrow: '<div class="nextArrow"></div>',
		slidesToShow: 3,
		dots: false,

		responsive: [
			{
				breakpoint: 1050,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
				},
			},
		],
	});

	$(".js-slider-content").slick({
		autoplay: true,
		infinite: true,
		arrows: false,
		fade: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		asNavFor: ".js-slider-image",
		dots: true,
		appendDots: ".js-slider-nav",
	});

	$(".js-slider-image").slick({
		infinite: true,
		arrows: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		asNavFor: ".js-slider-content",
	});

	$(".js-commercial-member-image").slick({
		infinite: true,
		arrows: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		fade: true,
		asNavFor: ".js-commercial-member-content",
		dots: true,
		appendDots: ".js-commercial-member-dot",
	});

	$(".js-commercial-member-content").slick({
		infinite: true,
		arrows: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		fade: true,
		nextArrow: ".js-next-member",
		prevArrow: "",
		asNavFor: ".js-commercial-member-image",
	});

	$(".js-commercial-projets").slick({
		infinite: true,
		arrows: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow: ".c-commercial__projets-next",
		prevArrow: ".c-commercial__projets-prev",
		dots: true,
		appendDots: ".c-commercial__projets-dots",
	});

	$(".js-single-slider").slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: true,
		arrows: false,
		dots: false,
	});

	$(".js-partner-slider").slick({
		slidesToShow: 5,
		slidesToScroll: 1,
		autoplay: true,
		dots: false,
		arrows: false,
		autoplaySpeed: 2000,
		slide: ".c-partners__slide",
		responsive: [
			{
				breakpoint: 1100,
				settings: {
					slidesToShow: 4,
				},
			},
			{
				breakpoint: 900,
				settings: {
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 700,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
				},
			},
		],
	});

	$(".c-gallery").slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		fade: false,
		asNavFor: ".c-gallery_nav",
		slide: ".c-gallery_item",
	});
	$(".c-gallery_nav").slick({
		slidesToShow: 5,
		slidesToScroll: 1,
		asNavFor: ".c-gallery",
		dots: false,
		focusOnSelect: true,
	});

	$(document).ready(function () {
		$(".c-product-listings_carousel").slick({
			slidesToShow: 3,
			slidesToScroll: 1,
			arrows: true,
			fade: false,
			slide: ".c-product-listings_slide",
			prevArrow: $(".c-products-listing_arrow.-prev"),
			nextArrow: $(".c-products-listing_arrow.-next"),

			responsive: [
				{
					breakpoint: 700,
					settings: {
						slidesToShow: 2,
					},
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
					},
				},
			],
		});
	});
}
