export default function () {
	jQuery(".c-complex-header_play").click(function () {
		jQuery(this).parent().parent().find(".c-complex-header_video").addClass("is-open");
	});

	var $ = jQuery;

	$(".c-video:not(.is-init) .c-video_play").click(function () {
		var $video = $(this).closest(".c-video");

		if ($video.hasClass("is-playing")) {
			$video.addClass("is-paused");
			$video.removeClass("is-playing");
			var video = $video.find("video")[0];
			video.pause();
		} else {
			$video.addClass("is-init");
			$video.removeClass("is-ended");
			$video.removeClass("is-paused");
			$video.addClass("is-playing");
			var video = $video.find("video")[0];
			video.play();
			fadeInAudio(video);
		}
	});

	$(".c-video.has-replay video").on("ended", function () {
		var $video = $(this).closest(".c-video");
		$video.addClass("is-ended");
		$video.removeClass("is-playing");
	});

	function fadeInAudio(video) {
		var volume = 0;
		video.volume = volume;

		var interval = setInterval(() => {
			volume = volume + 0.1;
			volume = parseFloat(volume.toFixed(1));
			video.volume = volume;
			if (volume >= 1) {
				clearInterval(interval);
			}
		}, 100);
	}
}
