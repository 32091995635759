import $ from "jquery";

export default function () {
	$.fn.isInViewport = function () {
		var elementTop = $(this).offset().top;
		var elementBottom = elementTop + $(this).outerHeight();

		var viewportTop = $(window).scrollTop();
		var viewportBottom = viewportTop + $(window).height();

		return elementBottom > viewportTop && elementTop < viewportBottom;
	};

	$(window).on("resize scroll", function () {
		$(".o-section:not(.-not-anim)").each(function () {
			if ($(this).isInViewport()) {
				$(this).addClass("is-inview");

				if ($(this).hasClass("c-team-members")) {
					$(this).addClass("is-inview");
				}
			}
		});

		$(".c-timeline_event").each(function () {
			if ($(this).isInViewport()) {
				$(this).addClass("is-inview");
			}
		});
	});

	var findMiddleElement = (function (docElm) {
		var viewportHeight = docElm.clientHeight,
			elements = $(".c-timeline_event");

		return function (e) {
			var middleElement;
			if (e && e.type == "resize") viewportHeight = docElm.clientHeight;

			elements.each(function () {
				var pos = this.getBoundingClientRect().top;
				// if an element is more or less in the middle of the viewport
				if (pos > viewportHeight / 2 && pos < viewportHeight / 1.5) {
					middleElement = this;
					$(this).addClass("is-active");
					return false;
				} else {
					$(this).removeClass("is-active");
				}
			});
		};
	})(document.documentElement);

	$(window).on("scroll resize", findMiddleElement);
}
